<template>
<div class="second">
  <div
      class="tv-img"
      :style="`background-image: url(${category.cover_url})`"
  >
    <div class="container">
      <h2
          class="title"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="500"
      >{{ category.name }}</h2>
    </div>
  </div>
  <div class="sub-product">
    <div class="product-component-section"
         v-for="(childCategory, index) in category.children" :key="index"
    >
      <div class="product-component-title">
        <div class="container">
          <h2 class="section-title">{{ childCategory.name }}</h2>
        </div>
      </div>
      <div class="container">
        <div class="product-component-section-block">
          <div class="product-component-section-right">
            <div v-for="product in childCategory.products" :key="`product-${product.id}`"
                 class="product-component-section-right-item">
              <router-link :to="{name: 'products-product', params: {product: product.id}}">
                <div>
                  <img :src="product.icon_url" alt="img">
                </div>
                <p>{{ product.name }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
import {mapState} from "vuex/dist/vuex.esm.browser";

export default {
  name: "Category",
  components: {Footer},
  data(){
    return {
      category: null,
    }
  },
  computed: {
    ...mapState(['secondId'])
  },
  mounted() {
    window.scrollTo(0,0)
  },
  methods: {

  },
   async created() {
    this.category = (await this.axios.get(`/categories/${this.$route.params.secondCategory}`)).data || {}
  },
  watch:{
     async secondId(){
      this.category = (await this.axios.get(`/categories/${this.$route.params.secondCategory}`)).data || {}
       window.scrollTo(0,0)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.second{
  .tv-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .container{
      padding: 414px 0 77px;
      .title{
        margin-left: 40px;
        color: $white;
      }
    }
  }
  .sub-product{
    background-color: $white;
    .product-component-title{
      background-color: rgba(164, 163, 163, 0.9);
      .section-title{
        padding: 9px 0 9px 40px;
        color: $white;
      }
    }
    .product-component-section{
      padding: 54px 0 87px;
      .container{
        .product-component-section-block{
          width: 100%;
          display: flex;
          justify-content: space-between;
          &:first-child{
            margin-top: 0;
          }
          .product-component-section-right{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .product-component-section-right-item{
              margin-top: 20px;
              width: 25%;
              & > a{
                margin: 0 auto;
                width: calc(365 / 1440 * 100%);
                & > div{
                  width: 100%;
                  height: 182px;
                  display: flex;
                  justify-content: center;
                  & > img{
                    max-width: 100%;
                    object-fit: contain;
                  }
                }
                & > p{
                  font-style: normal;
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 26px;
                  text-align: center;
                  letter-spacing: 0.16px;
                  color: #3A3A3A;
                  padding: 0 20px;
                  margin-top: 20px;
                }
                &:last-child{
                  margin-right: 0;
                }
              }
              &:hover{
                background-color: rgba(164, 163, 163, 0.9);
                transform: scale(1.1, 1.1);
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                cursor: pointer;
                transition-duration: 300ms;
                transition-delay: 150ms;
                border-radius: 3px;
                img{
                }
              }
            }
          }
        }
      }
    }
    &:nth-child(2){
      padding-top: 49px;
    }
  }
}
@media (max-width: 1400px) {
  .second{
    .tv-img{
      .container{
        padding: 330px 0 77px;
      }
    }
  }
}
@media (max-width: 768px) {
  .second{
    .tv-img{
      .container{
        padding: 250px 0 77px;
        .title{
          font-size: 40px;
        }
      }
    }
    .sub-product{
      .product-component-section{
        padding: 0 0 50px;
        .container{
          .product-component-section-block{
            .product-component-section-right{
              .product-component-section-right-item{
                width: calc(100% / 3);
              }
            }
          }
        }
      }
    }
  }
}
</style>
